<template>
  <v-main>
    <v-toolbar dark color="primary" dense>
      <v-btn icon dark @click="$router.go(-1)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title
        >{{ patient.lastName }} - {{ patient.firstName }}
        <span class="subtitle-2">{{ this.$route.query.customerName }}</span></v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="$router.go(-1)"> Close </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <PatientDetails :patient="patient" />
  </v-main>
</template>

<script>
import PatientDetails from "../components/PatientDetails.vue";
import PatientService from '../services/PatientService';

export default {
  components: {
    PatientDetails,
  },
  data: () => ({
      patient:{},
      loading:false
  }),
  methods:{
    lookupPatient:function()
    {
    this.loading = true;        
    new PatientService().get(this.$route.query.customerId,this.$route.query.patientId) 
                        .then((value)=>{                                          
                            this.patient = value;                                                                               
                        })
                        .finally(() => (this.isLoading = false));
    },
  },
  mounted(){
      this.lookupPatient();
  }
};
</script>
